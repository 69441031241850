// import {SET_ALL_CATEGORY, ADD_CATEGORY, SET_CATEGORY_LOADER, CLEAR_DATA} from '../actions/types';

const initialState = {
    english: {
        para1: 'EXPERTS WITH 360° INNOVATIVE SOLUTION',
        para2: 'We fuse expertise with user-centered design and large scale infrastructural skills.',
        para3: 'WHO WE ARE',
        para4: 'Badan is a full service landscape architectural design specialist and construction firm ',
        para5: 'with an innovative, collaborative, modern and sustainable approach to enhancing places for people',
        para6: 'OUR PROMISES',
        para7: 'Over the past 40 years, we have gained a reputation for consistently delivering exceptional results for our clients throughout the lifecycle of their landscapes.',
        para8: 'We offer high quality landscaping services and products to a diverse range of clients. We take a collaborative, knowledge-sharing and ethical approach to our projects where integrity, honesty and fairness is guaranteed.',
        para9: 'OUR VISION',
        para10: 'Our vision is to be Saudi Arabia’s leading integrated landscape specialist architecture, design, and engineering and construction firm - delivering award-winning solutions that enhance the public and private communities they serve.',
        para11: 'OUR PHILOSOPHY',
        para12: 'Since 1980, our founding principles of innovation and sustainability continue to shape the firms work. Research-led and powered by collaboration, we are driven by a philosophy and belief that imaginative and innovative landscape design can be harnessed to deliver positive change and enrich lives.',
        para13: 'OUR PASSION',
        para14: 'We are passionate in our belief about the role progressive landscape design has to improve everyday life. To achieve this, we recognize that great landscape design stems from great people and great leadership. ',
        para15: 'Setting Badan apart is its creative value engineering methodology',
        para16: 'throughout the lifecycle of our clients’ landscapes.',
        checkList:['Research','Advanced Technologies','Attention to Detail','Quality','Project Management'],
        para17: 'OUR APPROACH TO PLACEMAKING',
        para18: 'Our aim is to affect positive change through inventive interfacing of landscape and architectural design and planning to enhance everyday experiences and enrich quality of life.',
        para19: 'Our aim is to affect positive change through inventive interfacing of landscape and architectural design and planning to enhance everyday experiences and enrich quality of life. We therefore boldly engage communities and stakeholders so that we can thoughtfully translate their vision into realized landscapes of lasting significance, which connects neighborhoods and habitats for current and future generations.',
        para20: 'OUR INTEGRATED LANDSCAPE MANAGEMENT PROCESS',
        para21: 'OUR INTERNATIONAL STANDARDS CERTIFICATES',
        para22: 'Our ISO quality certificates are a testament to our commitment to international standards.',
        galleryImages : [
          {
              id: 1,
              url: "/images/about-us-1.jpg",
              title: "Award-winning Unparalleled Service"
          },
          {
              id: 2,
              url: "/images/about-us-2.jpg",
              title: "Highest Standards"
          },
          {
              id: 3,
              url: "/images/about-us-3.jpg",
              title: "Quality Service and Design"
          },
          {
              id: 4,
              url: "/images/about-us-4.jpg",
              title: "Collaborative Approach"
          },
          {
              id: 5,
              url: "/images/about-us-5.jpg",
              title: "Environment Protection and Rehabilitation"
          },
          {
              id: 6,
              url: "/images/about-us-6.jpg",
              title: "Efficient and Cost-Effective Results"
          },
          {
              id: 7,
              url: "/images/about-us-7.jpg",
              title: "Integrated Range of Products and Services"
          },
          {
              id: 8,
              url: "/images/about-us-8.jpg",
              title: "Specialized Focus on Saudi Arabia Market"
          },
          {
              id: 9,
              url: "/images/about-us-9.jpg",
              title: "Highly Qualified, Multi-Skilled Team"
          }
      ],
    },
    arabic: {
      para1: 'خبراء مع حل مبتكر 360 درجة',
      para2: 'نحن ندمج الخبرة مع التصميم الذي يركز على المستخدم ومهارات البنية التحتية على نطاق واسع',
      para3: 'من نحن',
      para4: 'نهج شركة بادان هو تنفيذ وتنسيق الحدائق بطريقة إبداعية تلائم الحياة العصرية، بأساليب طبيعية',
      para5: 'مع نهج مبتكر ، تعاوني ، حديث ومستدام لتعزيز الأماكن للناس',
      para6: 'وعودنا',
      para7: 'اكتسبت بادان سمعة طيبة خلال الأربعين عامًا الماضية بسبب نتائح مشاريعها المبهرة لعملائها',
      para8: 'تتبع بادان نهجًا تعاونيًا ومشاركًا للمعارف والقيم في مشاريعها، متمسكة بالنزاهة والأمانة والإنصاف. تقدم بادان خدمات ومنتجات الحدائق فائقة الجودة لمجموعة متنوعة من العملاء',
      
      para9: 'رؤيتنا',
      para10: 'أن تكون بادان هي الشركة الرائدة في مجال الهندسة المعمارية للطبيعة والبناء في المملكة العربية السعودية، لتقديم حلول تحوز على الجوائز، كما تعزز البيئات العامة والخاصة التي تخدمها',
      para11: 'فلسفتنا',
      para12: 'منذ سنة 1980 تستمر مبادئ بادان الأساسية للابتكار والاستدامة في صياغة عملها، متزودة بالأبحاث، مستمدة طاقتها من إيمانها بأن تصميم المناظر الطبيعية المبتكرة حافز قوي للإيجابية وإثراء حياة الناس.',
      para13: 'شغفنا',
      para14: 'إدراك الدور الذي يلعبه تصميم المناظر الطبيعية في تحسين الحياة اليومية، حيث أنه يساهم في تغيير الحالة النفسية للأفراد. كما نفخر بإدارة أعمال مربحة ومستدامة، توفر أعلى مستوى من الجودة والخدمة للعملاء',
      para15: ' ما يميز بادان هو منهجية هندسة القيمة الإبداعية',
      para16: 'طوال دورة حياة المناظر الطبيعية لعملائنا',

      checkList:['ابحاث','التقنيات المتقدمة','الانتباه إلى التفاصيل','جودة','ادارة مشروع'],

      para17: 'نهجنا في صناعة المكان',
      para18: 'المساحات العامة والخاصة بطرق متعددة الأوجه. كما أننا نعتقد بأنه يجب أن تتم الاستفادة من ثوابت المجتمع المحلي بحسب إمكاناته المتنوعة والملهمة. حيث أننا نهدف بإنشاء مساحات تعزز من صحة الأفراد ورفاهيتهم',
      para20: 'عملية إدارة المناظر الطبيعية المتكاملة لدى بادان',
      para21: 'شهادة جودة المقاييس الدولية',
      para22: 'شهادات الجودة ISO الخاصة بنا هي شهادة على التزامنا بالمعايير الدولية.',
      galleryImages : [
        {
            id: 1,
            url: "/images/about-us-1.jpg",
            title: "تقديم خدمة مثالية ذات مقاييس ومواصفات عالمية تستحق. ثقة العملاء"
        },
        {
            id: 2,
            url: "/images/about-us-2.jpg",
            title: "أعلى المعايير"
        },
        {
            id: 3,
            url: "/images/about-us-3.jpg",
            title: "جودة الخدمة والتصميم"
        },
        {
            id: 4,
            url: "/images/about-us-4.jpg",
            title: "الشراكة مع عملائنا"
        },
        {
            id: 5,
            url: "/images/about-us-5.jpg",
            title: "حماية البيئة وإعادة التأهيل"
        },
        {
            id: 6,
            url: "/images/about-us-6.jpg",
            title: "وفرة النتائج مع توفير التكاليف"
        },
        {
            id: 7,
            url: "/images/about-us-7.jpg",
            title: "مجموعة متكاملة من المنتجات وخدمات الحدائق والمناظر الطبيعية"
        },
        {
            id: 8,
            url: "/images/about-us-8.jpg",
            title: "تركيز خاص على السوق السعودية"
        },
        {
            id: 9,
            url: "/images/about-us-9.jpg",
            title: "الفريق الإداري المحترف والمؤهل على أعلى مستوى"
        }
    ],
  },
  };
  
  const aboutUsReducer = (state = initialState, action) => {
    switch (action.type) {
      default:
        return { ...state };
    }
  };
  
  export default aboutUsReducer;
  