import React from 'react'
import AppCarousel from '../common/app-carousel/AppCarousel';
import './ChooseUs.scss';
import { Carousel } from 'react-bootstrap';
import { connect } from 'react-redux';

const medias = [
    {
        id: 1,
        url: "/videos/whybadan.mp4",
        mediaType: "video"
    }
];


const ChooseUs = ({arabic, english, language}) => {
    const content = language === 'en' ? english :  arabic;
    const {carousels} = content
    return (
        <div>
            <AppCarousel
                title={content.para1}
                titleAnimationDelay="1s"
                subTitleAnimationDelay="10s"
                medias={medias} />
            
            <section className="min-vh-100 py-5 page-section">
                <div className="container">
                    <div className="text-center mb-5">
                        <h1 className="text-primary font-weight-bold mb-4">{content.para2}</h1>
                        <p className="font-medium px-4">{content.para3}</p>
                    </div>
                    <div>
                        <h3 className="text-center text-primary mb-4">{content.para4}</h3>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="choose-us-card">
                                    <div className="position-relative text-center mb-3 overflow-hidden">
                                        <img className="" src="/images/whycu-1.jpeg" alt="" />
                                        <h4 className="position-absolute text-white font-weight-bold">{content.para5}</h4>
                                    </div>
                                    <p className="font-medium text-center">{content.para6}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="choose-us-card">
                                    <div className="position-relative text-center mb-3 overflow-hidden">
                                        <img className="" src="/images/whycu-2.jpeg" alt="" />
                                        <h4 className="position-absolute text-white font-weight-bold">{content.para7}</h4>
                                    </div>
                                    <p className="font-medium text-center">{content.para8}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="choose-us-card">
                                    <div className="position-relative text-center mb-3 overflow-hidden">
                                        <img className="" src="/images/whycu-3.jpeg" alt="" />
                                        <h4 className="position-absolute text-white font-weight-bold">{content.para9}</h4>
                                    </div>
                                    <p className="font-medium text-center">{content.para10}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="vh-100 py-5" style={{backgroundColor: '#e4e4e4'}}>
                <div className="container">
                    <div className="text-center mb-md-5">
                        <h1 className="text-primary mb-4 font-weight-bold">{content.para11}</h1>
                        <p className="font-medium px-md-5">
                        {content.para12}
                        </p>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-9">
                            <Carousel indicators={false} className="our-values-carousel">
                            {carousels.map((med, i) => (
                                <Carousel.Item key={med.id}>
                                    <img
                                    className="d-block w-100"
                                    src={med.url}
                                    alt="First slide"
                                    style={{objectFit: 'cover'}}
                                    />
                                <Carousel.Caption>
                            <h3 className="font-weight-bold">{med.title}</h3>
                            <p className="font-medium">{med.description}</p>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            ))}
                            </Carousel>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

const mapStateToProps = (state) => ({
    language: state.home.language,
    english: state.chooseUs.english,
    arabic: state.chooseUs.arabic,
  });
  
 export default connect(mapStateToProps, {})(ChooseUs);
