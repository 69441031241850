// import {SET_ALL_CATEGORY, ADD_CATEGORY, SET_CATEGORY_LOADER, CLEAR_DATA} from '../actions/types';

const initialState = {
	english: {
		para1: 'HEADQUARTERED IN RIYADH',
		para2: "We are at the heart of the Kingdom's economic hub",
		para3: 'OUR LOCATION',
		para4: 'Proudly headquartered in our nation’s capital Riyadh, and serving our surrounding communities, we are focused on being the most valued within Saudi Arabia.',
		para5: 'PLEASE CONTACT US',
		para6: '+966(11)4649442 / 4650408',
		para7: 'info@badan.com.sa',
		para8: '3100 Prince Sultan Bin Abdulaziz Rd, As Sulimaniyah, P.O. Box 5370, Riyadh 11422',
		para9: 'SUBMITING...',
		para10: 'SUBMIT',
		para11: 'Success',
		para12: 'Thank you for reaching out us.',
		para13: 'Full Name',
		para14: 'Email Address',
		para15: 'Subject',
		para16: 'Phone Number',
		para17: 'Your Message'
	},
	arabic: {
		para1: 'المقر الرئيسي في الرياض',
		para2: "نحن في قلب المركز الاقتصادي للمملكة",
		para3: 'موقعنا',
		para4: 'يقع مقرنا الرئيسي بفخر في عاصمة بلادنا الرياض، ونخدم مجتمعاتنا المحيطة، ونحن لا نهدف إلى أن نكون أكبر شركة في المنطقة؛ بل نحن نركز على أن نكون الأكثر قيمة داخل المملكة العربية السعودية. ',
		para5: 'الرجاء التواصل معنا',
		para6: '+966(11)4649442 / 4650408',
		para7: 'info@badan.com.sa',
		para8: '3100 طريق الأمير سلطان بن عبد العزيز ، السليمانية ، ص. 5370 الرياض 11422',
		para9: 'تقديم...',
		para10: 'تقديم',
		para11: 'نجاح',
		para12: 'شكرا لك على التواصل معنا.',
		para13: 'الاسم بالكامل',
		para14: 'عنوان بريد الإكتروني ',
		para15: 'الموضوع',
		para16: 'رقم الهاتف',
		para17: 'رسالتك'
	}
};

const contactReducer = (state = initialState, action) => {
	switch (action.type) {
		default:
			return { ...state };
	}
};

export default contactReducer;
