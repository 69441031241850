// import {SET_ALL_CATEGORY, ADD_CATEGORY, SET_CATEGORY_LOADER, CLEAR_DATA} from '../actions/types';

const initialState = {
  english: {
    header: [
      {
        id: 1,
        name: "ABOUT BADAN",
        route: "/about",
      },
      {
        id: 2,
        name: "OUR SERVICES",
        route: "/services",
      },
      {
        id: 3,
        name: "OUR RESOURCES",
        route: "/resources",
      },
      {
        id: 4,
        name: "OUR PROJECTS",
        route: "/projects",
      },
      {
        id: 5,
        name: "WHY BADAN",
        route: "/choose-us",
      },
      {
        id: 6,
        name: "CONTACT",
        route: "/contact",
      },
    ],
    home: {
      para1: "LANDSCAPING A MODERN WORLD",
      para2:
        "Saudi’s Leading Commercial and Private Landscaping Company Delivering Exceptional Landscaping for 40 years",
    },
    footer: {
      addr1: 'Prince Sultan Bin Abdulaziz Rd, As Sulimaniyah',
      addr2: 'P.O. Box 5370, Riyadh 11422',
      addr3: 'Kingdom of Saudi Arabia',
      addr4: 'Phone: +966 (11) 4649442 / 4650408',
      addr5: 'Fax: +966 (11) 4644767',
      addr6: 'Email',

      para1: 'GET IN TOUCH'
    }
  },
  arabic: {
    header: [
      {
        id: 1,
        name: "عن بادان",
        route: "/about",
      },
      {
        id: 2,
        name: "خدماتنا",
        route: "/services",
      },
      {
        id: 3,
        name: "مواردنا",
        route: "/resources",
      },
      {
        id: 4,
        name: "مشاريعنا",
        route: "/projects",
      },
      {
        id: 5,
        name: "لماذا بدان",
        route: "/choose-us",
      },
      {
        id: 6,
        name: "اتصل",
        route: "/contact",
      },
    ],
    home: {
      para1: "لطبيعة معاصرة",
      para2:
        "الشركة السعودية الرائدة في مجال تصميم وتنفيذ وتنسيق الحدائق والمناظر الطبيعية، الخاصة والعامة تقديم المناظر الطبيعية الاستثنائية لمدة 40 عاما",
    },
    footer: {
      addr1: 'طريق الأمير سلطان بن عبد العزيز ، السليمانية',
      addr2: 'ص.ب 5370 الرياض 11422',
      addr3: 'المملكة العربية السعودية',
      addr4: 'جوال: +966 (11) 4649442/4650408',
      addr5: 'فاكس: +966 (11) 4644767',
      addr6: 'البريد الإلكتروني',

      para1: 'ابقى على تواصل'
    }
  },
  language: "en",
  arabicModal: false
};

const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_LANGUAGE":
      return {
        ...state,
        language: action.payload,
      };
      case "SHOW_MODAL":
        return {
          ...state,
          arabicModal: action.payload,
        };  
    default:
      return { ...state };
  }
};

export default homeReducer;
