import React, { useEffect } from 'react';
import './About.scss';
import AppCarousel from '../common/app-carousel/AppCarousel';
import { Container, Row, Col } from 'react-bootstrap';
import Gallery from '../common/gallery/Gallery';
import { connect } from 'react-redux';

const medias = [
    {
        id: 1,
        url: "/images/about-carousel-1.JPG",
        mediaType: "image",
        duration: 2000
    },
    {
        id: 2,
        url: "/images/about-carousel-2.jpg",
        mediaType: "image",
        duration: 2000
    },
    {
        id: 3,
        url: "/images/about-carousel-3.jpg",
        mediaType: "image",
        duration: 2000
    },
    {
        id: 4,
        url: "/images/about-carousel-4.jpg",
        mediaType: "image",
        duration: 2000
    },
    {
        id: 5,
        url: "/images/about-carousel-5.jpg",
        mediaType: "image",
        duration: 2000
    }
];

function About({ arabic, english, language }) {
    const sectionRef = React.createRef();
    const listRef = React.createRef();

    const scrollHandler = () => {
        if ((sectionRef.current.offsetTop - 300) <= document.documentElement.scrollTop) {
            listRef.current.classList.add("active");
        }
    }

    useEffect(() => {
        document.addEventListener('scroll', scrollHandler)
        return () => {
            document.removeEventListener('scroll', scrollHandler)
        };
    });

    const content = language === 'en' ? english : arabic;
    const { galleryImages } = content
    return (
        <div id="about-us">
            <AppCarousel
                title={content.para1}
                subTitle={content.para2}
                titleAnimationDelay="1s"
                isCarousel
                subTitleAnimationDelay="3s"
                medias={medias} />
            <section className="page-section who-we-are min-vh-100 d-flex">
                <Container dir="ltr" >
                    <Row>
                        <Col xs="12">
                            <h1 className="text-primary text-center font-weight-bold mt-5 mb-4">{content.para3}</h1>
                        </Col>
                        <Col xs="12" md="4" className="text-secondary">
                            <h3 className="mb-1 font-weight-bold">{content.para4}</h3>
                            <p>{content.para5}</p>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="page-section  d-flex">
                <Container dir="ltr" className="my-5" >
                    <Row>
                        <Col xs="12">
                            <h1 className="text-primary text-center font-weight-bold mt-5 mb-2 text-uppercase">{content.para21}</h1>
                        </Col>
                        <Col xs="12"  className="text-secondary">
                            <p className="text-center" >{content.para22}</p>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col xs="12" md="6" lg="3" className="d-flex" >
                            <div className="align-items-center d-flex justify-content-center">
                              <img className="img-thumbnail" width="70%" src="/images/ISO1.png" alt="ISO certificates" />
                            </div>
                        </Col>
                        <Col xs="12" md="6" lg="3" className="d-flex mt-3 mt-md-0" >
                            <div className="align-items-center d-flex justify-content-center">
                              <img className="img-thumbnail" width="70%" src="/images/ISO2.png" alt="ISO certificates" />
                            </div>
                        </Col>
                        <Col xs="12" md="6" lg="3" className="d-flex mt mt-3 mt-lg-0" >
                            <div className="align-items-center d-flex justify-content-center">
                              <img className="img-thumbnail" width="70%" src="/images/ISO3.jpeg" alt="ISO certificates" />
                            </div>
                        </Col>
                        <Col xs="12" md="6" lg="3" className="d-flex mt mt-3 mt-lg-0" >
                            <div className="align-items-center d-flex justify-content-center">
                              <img className="img-thumbnail" width="70%" src="/images/ISO4.jpeg" alt="ISO certificates" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="page-section promises">
                <Container >
                    <div className="px-md-5 mx-md-2">
                        <h1 className="text-primary text-center font-weight-bold mt-5 mb-4">{content.para6}</h1>
                        <p className="text-center mb-2 text-secondary">{content.para7}</p>
                        <p className="text-center text-secondary">{content.para8}</p>
                    </div>
                    <Gallery medias={galleryImages} />
                </Container>
            </section>
            <section className="page-section vision min-vh-100 d-flex">
                <Container className="d-flex jusify-content-end mt-md-4">
                    <Row className="text-white my-3 mt-md-5">
                        <Col lg={{ offset: language === 'en' ? 0 : 3 }} md="6" xs="12">
                            <h3 className="font-weight-bold">{content.para9}</h3>
                            <p className={`${language === 'en' ? 'pr-3' : 'pl-3'}`}>{content.para10}</p>
                        </Col>
                        <Col lg md="6" xs="12">
                            <h3 className="font-weight-bold">{content.para11}</h3>
                            <p className={`${language === 'en' ? 'pr-3' : 'pl-3'}`}>{content.para12}</p>
                        </Col>
                        <Col lg={{ offset: language === 'en' ? 3 : 0 }} md="6" xs="12">
                            <h3 className="font-weight-bold">{content.para13}</h3>
                            <p className={`${language === 'en' ? 'pr-3' : 'pl-3'}`}>{content.para14}</p>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section ref={sectionRef} className="page-section setting-badan min-vh-100 d-flex">
                <div className="w-100 content-wrapper d-flex flex-column">
                    <div className="bg-white"></div>
                    <div className="content w-100 py-3">
                        <Container className="d-flex h-100">
                            <Row className="my-auto text-white">
                                <Col xs="12" md="6">
                                    <div className="px-md-5 px-0">
                                        <h3 className="mb-1 font-weight-bold">{content.para15}</h3>
                                        <p>{content.para16}</p>
                                    </div>
                                </Col>
                                <Col xs="12" md="6">
                                    <ul ref={listRef} className="check-list px-md-5 px-0">
                                        {content.checkList.map(list => <li key={list} className="my-3">{list}</li>)}
                                    </ul>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className="bg-white"></div>
                </div>
            </section>
            <section className="page-section min-vh-100 approach">
                <Container>
                    <div className="px-md-5 mx-md-2 text-white ">
                        <h1 className="text-center font-weight-bold mt-5 mb-3">{content.para17}</h1>
                        <p className="text-center mb-2">{content.para18}</p>
                        <p className="text-center">{content.para19}</p>
                    </div>
                </Container>
            </section>
            <section className="page-section min-vh-100 d-flex lifecycle">
                <Container className="d-flex flex-column">
                    <h1 className="text-primary text-center font-weight-bold my-md-5 mt-4 mb-1 px-md-4">{content.para20}</h1>
                    <div className="lifecycle-img-bg mb-md-5 mb-3" style={{ backgroundImage: `url('/images/aboutus-lifecycle${language === 'en' ? '' : '_arabic'}.png')` }}></div>
                </Container>
            </section>
        </div >
    );
}

const mapStateToProps = (state) => ({
    english: state.aboutUs.english,
    arabic: state.aboutUs.arabic,
    language: state.home.language,
});

export default connect(mapStateToProps, {})(About);

