import React from 'react';
import './Footer.scss';
import { connect } from 'react-redux';

 function Footer({ language, arabicFooter, englishFooter }) {
    const contents = language === "en" ? englishFooter : arabicFooter;

    return (
        <div id="footer">
            <div className="container pb-4 pt-5">
                <div className="row justify-content-between mb-4">
                    <div className={`text-white text-center col-md-6 ${language==='en' ? 'text-md-left' : 'text-md-right'}`}>
                        <p className="m-0">3100 {contents.addr1}</p>
                        <p className="m-0">{contents.addr2}</p>
                        <p className="m-0">{contents.addr3}</p>
                        <p className="m-0">{contents.addr4}</p>
                        <p className="m-0">{contents.addr5}</p>
                        <p className="m-0">{contents.addr6}: info@badan.com.sa</p>
                    </div>
                    <div className="d-flex flex-column justify-content-between col-lg-2 col-md-3 my-md-0 my-4">
                        <h5 className="font-weight-bold text-primary text-center">{contents.para1}</h5>
                        <div className="d-flex justify-content-center">
                            <a href="" className="text-white mr-2" style={{textDecoration: 'none'}}>
                                <i className="icon-social-facebook-circular h1"/>
                            </a>
                            <a href="" className="text-white mx-2" style={{textDecoration: 'none'}}>
                                <i className="icon-social-twitter-circular h1"/></a>
                            <a href="" className="text-white ml-2" style={{textDecoration: 'none'}}>
                                <i className="icon-social-linkedin-circular h1"/>
                            </a>
                        </div>
                    </div>
                </div>
                <p className="text-white m-0 text-center text-md-left">© Copyright 1980-2019 / BADAN by Badan Agricultural & Contracting / All Rights Reserved / Powered by SmartTech</p>
            </div>
        </div>
    )
}

  const mapStateToProps = (state) => ({
    englishFooter: state.home.english.footer,
    arabicFooter: state.home.arabic.footer,
    language: state.home.language,
  });

export default connect(mapStateToProps)(Footer)
