// import {SET_ALL_CATEGORY, ADD_CATEGORY, SET_CATEGORY_LOADER, CLEAR_DATA} from '../actions/types';

const initialState = {
    english: {
        para1: 'WE ARE SYSTEMATIC LANDSCAPE SPECIALISTS',
        para2: 'OUR CORE SERVICES',
        para3: 'Our expertise spans across a range of project types and scales from bespoke private gardens to complex large-scale urban landscape master planning and design projects, parks and squares, open space restorations, recreational venues,  governmental-plus-corporate landscapes.',
        para4: 'OUR RANGE OF LANDSCAPING PROJECTS INCLUDE',
        gallery : [
            {
                id: 1,
                imgUrl: '/images/services-1.jpeg',
                title: 'Hard Landscaping & Stonework',
            },
            {
                id: 2,
                imgUrl: '/images/services-2.jpeg',
                title: 'Soft Landscaping',
            },
            {
                id: 3,
                imgUrl: '/images/services-3.jpeg',
                title: 'Irrigation Systems',
            },
            {
                id: 4,
                imgUrl: '/images/services-4.jpeg',
                title: 'Lagoons',
            },
            {
                id: 5,
                imgUrl: '/images/services-5.jpeg',
                title: 'Environment Rehabilitation',
            },
            {
                id: 6,
                imgUrl: '/images/services-6.jpeg',
                title: 'Civic Works',
            },
      ],
    },
    arabic: {
        para1: 'متخصصون ومنهجيون',
        para2: 'خدماتنا الأساسية',
        para3: '.تمتد خبرة بادان عبر مجموعة من مختلف المشاريع والمقاييس من الحدائق الخاصة حسب الطلب، إلى مشاريع التخطيط الرئيسية للمناظر الطبيعية معقدة التصميم وواسعة النطاق، والحدائق والساحات العامة، وترميم المساحات المفتوحة، والأماكن الترفيهية، بالإضافة إلى المناظر الطبيعية للشركات، وحرم الجامعات، والمناظر الطبيعية العامة، وحدائق المدن',
        para4: 'تشمل مجموعة من المشاريع الساحلية',
        gallery : [
            {
                id: 1,
                imgUrl: '/images/services-1.jpeg',
                title: 'الأشجار الصلبة وأعمال الحجارة',
            },
            {
                id: 2,
                imgUrl: '/images/services-2.jpeg',
                title: 'الحدائق الناعمة',
            },
            {
                id: 3,
                imgUrl: '/images/services-3.jpeg',
                title: 'نظم الري',
            },
            {
                id: 4,
                imgUrl: '/images/services-4.jpeg',
                title: 'البحيرة',
            },
            {
                id: 5,
                imgUrl: '/images/services-5.jpeg',
                title: 'إعادة تأهيل البيئة',
            },
            {
                id: 6,
                imgUrl: '/images/services-6.jpeg',
                title: 'الأعمال المدنية',
            },
      ],
    },
  };
  
  const servicesReducer = (state = initialState, action) => {
    switch (action.type) {
      default:
        return { ...state };
    }
  };
  
  export default servicesReducer;
  