import React from 'react';
import AppCarousel from '../common/app-carousel/AppCarousel';
import { Carousel } from 'react-bootstrap';
import './Resources.scss';
import { connect } from 'react-redux';

const medias = [
	{
        id: 1,
        url: "/images/resource-carousel-1.jpeg",
        mediaType: "image",
        duration: 2000
    },
    {
        id: 2,
        url: "/images/resource-carousel-2.JPG",
        mediaType: "image",
        duration: 2000
    },
    {
        id: 3,
        url: "/images/resource-carousel-3.jpeg",
        mediaType: "image",
        duration:2000
    },
    {
        id: 4,
        url: "/images/resource-carousel-4.jpeg",
        mediaType: "image",
        duration:2000
    },
];

const carousel = [
	{
		id: 2,
		url: "/images/resources-2.jpeg",
	},
	{
		id: 3,
		url: "/images/resources-3.jpeg",
	},
	{
		id: 4,
		url: "/images/resources-4.jpeg",
	},
	{
		id: 5,
		url: "/images/resources-5.jpeg",
	},
	{
		id: 6,
		url: "/images/resources-6.jpeg",
	},
	{
		id: 1,
		url: "/images/resources-1.jpeg",
	},
]
function Resources({arabic, english, language}) {
	const content = language === 'en' ? english :  arabic;
	return (
		<div id="our-resources">
			<AppCarousel
                title={content.para1}
                subTitle={content.para2}
                titleAnimationDelay="1s"
				subTitleAnimationDelay="3s"
				isCarousel
                medias={medias} />

			<section className="page-section">
				<div className="text-center container">
					<h1 className="text-primary font-weight-bold my-4">{content.para3}</h1>
					<p className="font-medium">{content.para4}</p>
				</div>
				<Carousel indicators={false} className="nurseries-carousel">
					{carousel.map(med=> (
						<Carousel.Item key={med.id}>
							<img
								className="d-block w-100 nurseries-carousel-img"
								src={med.url}
								alt="First slide"
							/>
						</Carousel.Item>
					))}
				</Carousel>
			</section>
			<section className="equipment">
				<div className="text-center container">
					<h1 className="text-primary font-weight-bold my-4">{content.para5}</h1>
				</div>
				<div className="equipment-img">
					<div className="row no-gutters h-100 align-items-center">
						<div className="col-md-6 offset-md-6">
							<p className="m-0 mx-auto w-50 text-center">{content.para6}</p>
						</div>
					</div>
				</div>
			</section>
			{/* <section className="location vh-100 position-relative d-flex flex-column justify-content-center">
				<div className="text-center">
					<h1 className="font-weight-bold text-white pb-md-5">Where you can find our Nurseries ?</h1>
				</div>
				<div className="map-container text-center">
					<h3 className="font-weight-bold text-white py-4" >For more information about our Nurseries you can visit us on this locations</h3>
					<div className="row justify-content-center mx-0">
						<div className="col-md-7"> */}
						{/* <iframe title="map" 
								width="100%" 
								height="350" 
								src="https://maps.google.com/maps?width=100%&height=600&hl=en&q=Malet%20St%2C%20London%20WC1E%207HU%2C%20United%20Kingdom+(Your%20Business%20Name)&ie=UTF8&t=&z=14&iwloc=B&output=embed" 
								frameborder="0" scrolling="no" marginheight="0" marginwidth="0" /> */}
							{/* <iframe
                                title="Badan"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14848.093562810634!2d39.240749971304425!3d21.506803735631664!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c3cdef3e6cac95%3A0x211e6631b7d47c35!2sSulaymaniyah%2C%20Jeddah%2022253%2C%20Saudi%20Arabia!5e0!3m2!1sen!2sin!4v1571333142399!5m2!1sen!2sin"
                                width="100%" 
                                height="350" 
                                frameBorder="0"
                                allowFullScreen="" />
						</div>
					</div>
				</div>
			</section> */}
			
		</div>
	)
}

const mapStateToProps = (state) => ({
    english: state.resources.english,
    arabic: state.resources.arabic,
    language: state.home.language,
  });

export default connect(mapStateToProps)(Resources)
