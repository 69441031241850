import React from "react";
import AppCarousel from "../common/app-carousel/AppCarousel";
import { connect } from "react-redux";

const medias = [
  {
    id: 1,
    url: "/images/home-1.jpg",
    mediaType: "image",
  },
  {
    id: 2,
    url: "/images/home-2.jpg",
    mediaType: "image",
  },
  {
    id: 3,
    url: "/videos/home.mp4",
    mediaType: "video",
    duration: 8000,
  },
  {
    id: 4,
    url: "/images/home-4.jpg",
    mediaType: "image",
  },
  {
    id: 5,
    url: "/images/home-5.jpg",
    mediaType: "image",
  },
  {
    id: 6,
    url: "/images/home-6.jpg",
    mediaType: "image",
  },
  {
    id: 7,
    url: "/images/home-7.jpg",
    mediaType: "image",
  },
];
function Home({ englishHome, arabicHome, language }) {
  const content = language === "en" ? englishHome : arabicHome;
  return (
    <AppCarousel
      medias={medias}
      title={content.para1}
      subTitle={content.para2}
      titleAnimationDelay="1s"
      subTitleAnimationDelay="3s"
      isCarousel
    />
  );
}

const mapStateToProps = (state) => ({
  englishHome: state.home.english.home,
  arabicHome: state.home.arabic.home,
  language: state.home.language,
});

export default connect(mapStateToProps, {})(Home);
