// import {SET_ALL_CATEGORY, ADD_CATEGORY, SET_CATEGORY_LOADER, CLEAR_DATA} from '../actions/types';

const initialState = {
    english: {
        para1: 'OUR PEOPLE ARE AT THE HEART OF EVERYTHING WE DO',
        para2: 'Our multi-national team brings a diverse range of skills that fuse expertise in landscape architecture, architecture, art, irrigation, electrical, mechanical and civil engineering with user centered and large scale infrastructural design.',
        para3: 'OUR NURSERIES',
        para4: 'As part of our fully integrated landscape management process we own and manage nurseries. These provide a range of drought tolerant, climate specific palms, trees, shrubs, climbers and ground cover. To complement our own stock, we also manage importation of special plants from Europe, Asia and America.',
        para5: 'OUR EQUIPMENT',
        para6: 'We are well-equipped, having invested in the latest, safe and efficient tools and machinery.',
    },
    arabic: {
        para1: '.في كل شيء نقوم به .. نحن نضع أفراد مجتمعنا أمام نصب أعيننا',
        para2: '.يجلب فريقنا متعدد الجنسيات مجموعة متنوعة من المهارات التي تدمج الخبرات في هندسة المناظر الطبيعية والهندسة المعمارية والفن والري والهندسة الكهربائية والميكانيكية والمدنية مع تصميم البنية التحتية التي تركز على المستخدم وعلى نطاق واسع',
        para3: 'حاضناتنا',
        para4: 'كجزء من عملية إدارة المناظر الطبيعية المتكاملة تمامًا، تمتلك بادان وتدير عدداً من المشاتل، توفر مجموعة من أشجار النخيل والأشجار المتسلقة، والغطاء الأرضي الذي يتحمل الجفاف. ولزيادة وتنويع مخزونها فإنها تستورد أيضاً نباتات خاصة مختارة بعناية من أنحاء العالم، تتناسب مع طبيعة وأجواء المملكة',
        para5: 'معداتنا',
        para6: '.نحن مجهزون تجهيزًا جيدًا، وقد استثمرنا في أحدث الأدوات والآلات الآمنة والفعالة',
    },
  };
  
  const resourceReducer = (state = initialState, action) => {
    switch (action.type) {
      default:
        return { ...state };
    }
  };
  
  export default resourceReducer;
  