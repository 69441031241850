import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import AppCarousel from '../common/app-carousel/AppCarousel';
import Collapse from 'react-bootstrap/Collapse'
import { Container, Table } from 'react-bootstrap';
import Gallery from '../common/gallery/Gallery';
import './Projects.scss'
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
// http://v2.badan.com.sa/api/collections/get/projects?token=921b7c8fd1c4d44d0366f7373a7ca6

const medias = [
    {
        id: 1,
        url: "/images/project-carousel-1.JPG",
        mediaType: "image",
        duration: 2000
    },
    {
        id: 2,
        url: "/images/project-carousel-2.JPG",
        mediaType: "image",
        duration: 2000
    },
    {
        id: 3,
        url: "/images/project-carousel-3.jpg",
        mediaType: "image",
        duration: 2000
    },
    {
        id: 4,
        url: "/images/project-carousel-4.JPG",
        mediaType: "image",
        duration: 2000
    },
    {
        id: 5,
        url: "/images/project-carousel-5.jpg",
        mediaType: "image",
        duration: 2000
    }
];

const baseUrl = "http://v2.badan.com.sa"
const Projects = ({ language, arabic, english }) => {

    const content = language === 'en' ? english : arabic;
    const [projects, setProjects] = useState([]);
    const [upcomingProjects, setUpcomingProjects] = useState([]);
    const [projectTypes, setProjectTypes] = useState([]);
    const [showChallenge, setShowChallenge] = useState(false);
    const [showImplementation, setShowImplementation] = useState(false);
    const [showResults, setShowResults] = useState(false);

    useEffect(() => {
        fetch('https://v2.badan.com.sa/api/collections/get/projects?token=921b7c8fd1c4d44d0366f7373a7ca6&sort[_created]=-1')
            .then(resp => resp.json()).then(res => {
                setProjects(res.entries);
            });

        fetch('https://v2.badan.com.sa/api/collections/get/project_types?token=921b7c8fd1c4d44d0366f7373a7ca6')
            .then(resp => resp.json()).then(res => {
                const pTypes = res.entries.map(item => {
                    return {
                        id: item._id,
                        url: baseUrl + item.project_types_image.path,
                        title: language === 'en' ? item.project_types_name : item.project_types_name_arabic
                    }
                });
                setProjectTypes(pTypes);
            });
        fetch('https://v2.badan.com.sa/api/collections/get/upcoming_projects?token=921b7c8fd1c4d44d0366f7373a7ca6')
            .then(resp => resp.json()).then(res => {
                console.log(res.entries);
                let upComingProjects = [];
                res.entries.forEach(project => {
                    const {
                        _id,
                        upcoming_projects_name,
                        upcoming_projects_challenge,
                        upcoming_projects_implementation,
                        upcoming_projects_end_result,
                        upcoming_projects_name_arabic,
                        upcoming_projects_challenge_arabic,
                        upcoming_projects_implementation_arabic,
                        upcoming_projects_end_result_arabic,
                        upcoming_projects_images
                    } = project;
                    const projectImages = upcoming_projects_images.map((image, index) => {
                        return {
                            _id: `${_id}_${index}`,
                            image_path: image.path,
                            upcoming_projects_name,
                            upcoming_projects_challenge,
                            upcoming_projects_implementation,
                            upcoming_projects_end_result,
                            upcoming_projects_name_arabic,
                            upcoming_projects_challenge_arabic,
                            upcoming_projects_implementation_arabic,
                            upcoming_projects_end_result_arabic
                        }
                    })
                    upComingProjects = [...upComingProjects, ...projectImages]
                })
                setUpcomingProjects([upComingProjects[0]]);
            });
    }, [language])



    return (
        <div id="projects">
            <AppCarousel
                title={content.para1}
                subTitle={content.para2}
                titleAnimationDelay="1s"
                subTitleAnimationDelay="3s"
                isCarousel
                medias={medias} />
            <section className="page-section1 mb-4">
                <Container>
                    <div className="px-md-5 mx-md-2 mb-5">
                        <h1 className="text-primary text-center font-weight-bold mt-5 mb-4">{content.para3}</h1>
                        <p className="text-center mb-2 text-secondary">{content.para4}</p>
                    </div>
                </Container>
            </section>
            <section className="upcoming-projects">
                <Carousel
                    interval={2500}
                    controls={false}
                    indicators={false}
                    fade={true}
                    touch={false}
                    pauseOnHover={false}
                    className="w-100">
                    {
                        upcomingProjects.map(project =>
                            <Carousel.Item
                                key={project._id}
                                style={{ backgroundImage: `url(${baseUrl}${project.image_path})` }}
                                className="h-100 w-100">
                                <div className="container text-white min-vh-100 d-flex flex-column align-items-center pt-4 pb-3" style={{ zIndex: 2 }}>
                                    <h2 className="font-weight-bold text-center mt-3">{language === 'en' ? project.upcoming_projects_name : project.upcoming_projects_name_arabic}</h2>
                                    <Row className="project-props mt-auto px-1 py-3 w-100">
                                        <Col xs="12" lg className="mb-lg-0 mb-3">
                                            <div onClick={() => setShowChallenge(!showChallenge)} className="d-flex flex-rows">
                                                <h5 className="font-weight-bold font-uppercase">{content.para5}</h5>
                                                <h5 className={`font-weight-bold font-uppercase cur-pointer ${language === 'en' ? 'ml-3' : 'mr-3'}`}>
                                                    {showChallenge ? '-' : '+'}
                                                </h5>
                                            </div>
                                            <Collapse in={showChallenge}>
                                                <p className={`m-0 ${language === 'en' ? 'text-left' : 'text-right'}`}>{language === 'en' ? project.upcoming_projects_challenge : project.upcoming_projects_challenge_arabic}</p>
                                            </Collapse>
                                        </Col>
                                        <Col xs="0" lg className="mb-lg-0 mb-3">
                                            <div onClick={() => setShowImplementation(!showImplementation)} className="d-flex flex-rows">
                                                <h5 className="font-weight-bold font-uppercase">{content.para6}</h5>
                                                <h5 className={`font-weight-bold font-uppercase cur-pointer ${language === 'en' ? 'ml-3' : 'mr-3'}`}>
                                                    {showImplementation ? '-' : '+'}
                                                </h5>
                                            </div>
                                            <Collapse in={showImplementation}>
                                                <p className={`m-0 ${language === 'en' ? 'text-left' : 'text-right'}`}>{language === 'en' ? project.upcoming_projects_implementation : project.upcoming_projects_implementation_arabic}</p>
                                            </Collapse>
                                        </Col>
                                        <Col xs="12" lg className="mb-lg-0 mb-3">
                                            <div onClick={() => setShowResults(!showResults)} className="d-flex flex-rows">
                                                <h5 className="font-weight-bold font-uppercase">{content.para7}</h5>
                                                <h5 className={`font-weight-bold font-uppercase cur-pointer ${language === 'en' ? 'ml-3' : 'mr-3'}`}>
                                                    {showResults ? '-' : '+'}
                                                </h5>
                                            </div>
                                            <Collapse in={showResults}>
                                                <p className={`m-0 ${language === 'en' ? 'text-left' : 'text-right'}`}>{language === 'en' ? project.upcoming_projects_end_result : project.upcoming_projects_end_result_arabic}</p>
                                            </Collapse>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="water-mark-project" />
                            </Carousel.Item>
                        )
                    }
                </Carousel>
            </section>
            <section className="page-section projects mb-5">
                <Container>
                    <h4 className="text-primary text-center font-weight-bold my-4">{content.para8}</h4>
                    <Gallery medias={projectTypes} />
                </Container>
            </section>
            <section className="page-section vh-100 ongoing-projects">
                <Container>
                    <h4 className="text-white text-center font-weight-bold mt-5 mb-4">{content.para9}</h4>
                    <Table responsive borderless className="projects-table mb-0">
                        <colgroup>
                            <col className="col-padding name" />
                            <col className="col-padding address" />
                            <col className="col-padding status" />
                        </colgroup>
                        <thead className="text-white">
                            <tr>
                                <th>{content.para10}</th>
                                <th>{content.para11}</th>
                                <th>{content.para12}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                projects.map(project => {
                                    return (
                                        <tr key={project._id}>
                                            <td>
                                                <p className="mb-0 font-weight-bold">{language === 'en' ? project.project_name : project.project_name_arabic}</p>
                                                {language === 'en' ? project.project_description : project.project_description_arabic}
                                            </td>
                                            <td className="text-wrap-pre-line">
                                                {language === 'en' ? project.project_address : project.project_address_arabic}
                                            </td>
                                            <td>{language === 'en' ? project.project_status : project.project_status_arabic}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </Container>
            </section>
        </div>
    );
};

const mapStateToProps = (state) => ({
    english: state.projects.english,
    arabic: state.projects.arabic,
    language: state.home.language,
});

export default connect(mapStateToProps)(Projects)
