// import {SET_ALL_CATEGORY, ADD_CATEGORY, SET_CATEGORY_LOADER, CLEAR_DATA} from '../actions/types';

const initialState = {
	english: {
		para1: 'EXPERTISE THAT MATTERS',
		para2: 'From playgrounds and parks to landscape master plans, we bring expertise in tackling projects of all shapes and sizes with a consistent focus on delivering high quality, value and service through innovative, contemporary design interventions.',
		para3: 'OUR PROJECTS',
		para4: 'Our expertise spans across a range of project types and scales from bespoke private gardens to complex large-scale urban landscape master planning and design projects, parks and squares, open space restorations, recreational venues,  governmental-plus-corporate landscapes.',
		para5: 'CHALLENGE',
		para6: 'IMPLEMENTATION',
		para7: 'END RESULT',
		para8: 'OUR RANGE OF LANDSCAPING PROJECTS INCLUDE',
		para9: 'OUR ONGOING AND RECENTLY COMPLETED PROJECTS',
		para10: 'Project Name',
		para11: 'Project Details',
		para12: 'Project Status'
	},
	arabic: {
		para1: 'الخبرة التي تهمك',
		para2: 'من الملاعب والحدائق إلى المخططات العامة والمناظر الطبيعية، تأتي بادان بخبرة مشهودة في معالجة المشاريع من جميع الأشكال والأحجام مع التركيز المستمر على تقديم جودة عالية، وخدمات مميزة، مع لمسات تصميم عصرية مبتكرة.',
		para3: 'مشاريعنا',
		para4: 'تمتد خبرة بادان عبر مجموعة من مختلف المشاريع والمقاييس من الحدائق الخاصة حسب الطلب، إلى مشاريع التخطيط الرئيسية للمناظر الطبيعية معقدة التصميم وواسعة النطاق، والحدائق والساحات العامة، وترميم المساحات المفتوحة، والأماكن الترفيهية، بالإضافة إلى المناظر الطبيعية للشركات، وحرم الجامعات، والمناظر الطبيعية العامة، وحدائق المدن.',
		para5: 'تحد',
		para6: 'التنفيذ',
		para7: 'النتيجة النهائية',
		para8: '	مجموعة من المشاريع الساحلية تشمل:-',
		para9: 'مشاريعنا الجارية والمستكملة مؤخرًا',
		para10: 'اسم المشروع',
		para11: 'تفاصيل المشروع',
		para12: 'حالة المشروع'
	}
};

const projectReducer = (state = initialState, action) => {
	switch (action.type) {
		default:
			return { ...state };
	}
};

export default projectReducer;
