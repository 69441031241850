// import {SET_ALL_CATEGORY, ADD_CATEGORY, SET_CATEGORY_LOADER, CLEAR_DATA} from '../actions/types';

const initialState = {
    english: {
        para1: 'FOCUS-DRIVEN WITH KEEN ATTENTION TO DETAILS',
        para2: "WHY BADAN?",
        para3: "Our approach to Placemaking is grounded in our multi-faceted approach to the planning, design and management of public and private spaces. We believe effective Placemaking should capitalize on a local community's assets, inspiration, and potential, with the intention of creating public spaces that promote people's health, happiness and well-being.",
        para4: 'We are focused on',
        para5: 'QUALITY',
        para6: 'We have an absolute focus on delivering high quality landscaping products, services and workmanship.',
        para7: 'VALUE',
        para8: 'We are driven by results that help you succeed and aim to offer cost effective solutions that are both efficient and economical.',
        para9: 'SERVICE',
        para10: 'Our firm offers consistent high-quality service across our fully integrated range of landscape architecture services.',
        para11: 'OUR VALUES',
        para12: ' We take great pride in running a profitable and sustainable business that delivers a consistently high level of service, quality and value to our clients.',
        carousels: [
            {
                id: 1,
                url: '/images/whycu-4.jpg',
                title: 'Collaborative',
                description: 'We foster a spirit of cooperation and respect and in all our relationships and partnerships. We are supportive of one another, our individual and collective skills, our experience and ambitions.'
            },
            {
                id: 2,
                url: '/images/whycu-5.jpg',
                title: 'Considerate',
                description: 'Conscious of only ever leaving a gentle footprint, we are considerate of each other, our wider social, economic and environmentally sustainable principles and the spaces and places we influence and inhabit.'
            },
            {
                id: 3,
                url: '/images/whycu-6.jpg',
                title: 'إلهام',
                description: 'We are passionate about creating stunning landscapes through imaginative extraordinary design.\nWe believe that it is the creative fusion of man-made environments entwining natural life with architecture that is key to any successful landscaping project.'
            },
            {
                id: 4,
                url: '/images/whycu-7.jpg',
                title: 'Innovative',
                description: 'We place people and communities at the center of our thinking to deliver engaging landscapes and inviting environments which are welcoming, unique and inspiring.'
            },
        ]
    },
    arabic: {
        para1: 'التركيز على الريادة مع الحرص الشديد على التفاصيل',
        para2: "لماذا بدان؟",
        para3: "يرتكز نهجنا في تحديد الأماكن المناسبة. والتي نعتمد فيها على التخطيط والتصميم وإدارة المساحات العامة والخاصة بطرق متعددة الأوجه. كما أننا نعتقد بأنه يجب أن تتم الاستفادة من ثوابت المجتمع المحلي بحسب إمكاناته المتنوعة والملهمة. حيث أننا نهدف بإنشاء مساحات تعزز من صحة الأفراد ورفاهيتهم.",
        para4: 'نحن نركز على',
        para5: 'جودة',
        para6: 'لدينا تركيز مطلق على تقديم المنتجات والخدمات التي تصنع المناظر الطبيعية بجودة عالية.',
        para7: 'القيمة',
        para8: 'حافز بادان هي النتيجة التي ستساعدك على النجاح وتهدف إلى تقديم حلول فعالة من حيث التكلفة التي تتسم بالكفاءة والتوفير. ',
        para9: 'الخدمات',
        para10: 'تقدم بادان خدمات منسقة عالية الجودة عبر مجموعتنا المتكاملة في خدمات هندسة المناظر الطبيعية.',
        para11: 'قيمنا',
        para12: 'نحن نفخر بإدارة أعمال مربحة ومستدامة تقدم مستوى عالٍ من الخدمة والجودة والقيمة لعملائنا.',
        carousels: [
            {
                id: 1,
                url: '/images/whycu-4.jpg',
                title: 'تعاون',
                description: 'نحن نعزز روح التعاون والاحترام في جميع علاقاتنا وشراكاتنا. نحن ندعم بعضنا البعض بمهاراتنا الفردية والجماعية، وخبراتنا وطموحاتنا.'
            },
            {
                id: 2,
                url: '/images/whycu-5.jpg',
                title: 'مراعاة',
                description: 'يهمنا ترك بصمة مميزة تتماشى مع مبادئنا الاجتماعية والاقتصادية التي تهدف إلى الاستدامة البيئية التي تؤثر في الأماكن التي نعيش فيها.'
            },
            {
                id: 3,
                url: '/images/whycu-6.jpg',
                title: 'الملهمة',
                description: 'نحن متحمسون لإنشاء مناظر طبيعية خلابة من خلال تصميم استثنائي خيالي. حيث أننا نعتقد أن الاندماج الإبداعي بين البيئات التي من صنع الإنسان هي التي تربط الحياة الطبيعية بالهندسة المعمارية فهي مفتاح لأي مشروع تنسيق حدائق ناجح.'
            },
            {
                id: 4,
                url: '/images/whycu-7.jpg',
                title: 'ابتكار',
                description: 'نحرص على وضع الأفراد والمجتمعات في أولويات تفكيرنا، لنقدم بيئة وطبيعة جاذبة وفريدة تكون مرحب بها دائمًا.'
            },
        ]
    }
};

const chooseUsReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return { ...state };
    }
};

export default chooseUsReducer;
