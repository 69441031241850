import React from 'react';
import { Navbar, Nav, Button } from 'react-bootstrap'
import './Header.scss';
import { LinkContainer } from 'react-router-bootstrap'
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'



function Header({ englishHome, arabicHome, language, dispatch, arabicModal }) {
    const contents = language === "en" ? englishHome : arabicHome;
    document.documentElement.lang = language
    document.documentElement.dir = language === 'ar' ? 'rtl' : 'ltr'
    return (
        <header>
            <Navbar className="this-navbar container" variant="dark" expand="lg" collapseOnSelect>
                <LinkContainer to="/">
                    <Navbar.Brand><img
                        src="/images/logo.png"
                        className="d-inline-block align-top"
                        alt="Badan logo"
                    /></Navbar.Brand>
                </LinkContainer>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse className={`flex-lg-column`} id="basic-navbar-nav">
                    <Nav className={`order-lg-1 ${language === 'ar' ? 'mr-lg-auto' : 'ml-lg-auto'}`}>
                        <LinkContainer exact to="/"><Nav.Link><span className="icon-home"></span></Nav.Link></LinkContainer>
                        {contents.map(content => <LinkContainer key={content.id} to={content.route}>
                            <Nav.Link>{content.name}</Nav.Link>
                        </LinkContainer>)}
                    </Nav>
                    <Button onClick={() => {
                        dispatch({
                            type: 'SET_LANGUAGE',
                            payload: language === "en" ? 'ar' : 'en'
                        })
                        dispatch({
                            type: 'SHOW_MODAL',
                            payload: true
                        })

                        setTimeout(() => {
                            dispatch({
                                type: 'SHOW_MODAL',
                                payload: false
                            })
                        }, 3000);

                    }}
                        className={`d-flex align-items-center language-selector p-0 order-lg-0 ${language === 'ar' ? 'mr-lg-auto' : 'ml-lg-auto'}`} variant="link">
                        <p className={`lang-select mb-0 text-white-50 ${language === 'ar' ? 'ml-2' : 'mr-2'}`}>{language === 'en' ? "Arabic" : "English"}</p>
                        <img className="" src={`/images/${language === 'en' ? 'saudiflag' : 'ukflag'}.png`} alt="" />
                    </Button>
                </Navbar.Collapse>
            </Navbar>
            <Modal backdrop={false} show={arabicModal} dialogClassName="arabic-modal m-0">
                <Modal.Body className="vh-100 arabic-modal-body">
                    <div className="d-flex justify-content-center align-items-center flex-column h-100">
                        <Spinner animation="grow" variant="light" />
                        <h1 className="text-white font-weight-bold text-center">{language === 'ar' ? "السلام عليكم ورحمة الله وبركاته" : 'Hello!'}</h1>
                        <p className="text-white">Loading your {language === 'ar' ? 'Arabic' : 'English'} content</p>
                    </div>
                </Modal.Body>
            </Modal>
        </header>
    );
}

const mapStateToProps = (state) => ({
    englishHome: state.home.english.header,
    arabicHome: state.home.arabic.header,
    language: state.home.language,
    arabicModal: state.home.arabicModal
});

export default connect(mapStateToProps)(Header)
