import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import './Contact.scss';
import AppCarousel from '../common/app-carousel/AppCarousel';
import { Toast } from 'react-bootstrap';
import { connect } from 'react-redux';

const medias = [
    {
        id: 1,
        url: "/videos/contacts.mov",
        mediaType: "video"
    }
];
const toastStyle = {
    position: 'fixed',
    top: '4rem',
    zIndex: 100,
    right: '1.5rem',
    backgroundColor: 'var(--primary)',
    color: '#fff',
}

function Contact({ language, english, arabic }) {

    const recaptchaRef = React.createRef();
    const content = language === 'en' ? english : arabic;
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');

    const [submiting, setSubmiting] = useState(false);
    const [show, setShow] = useState(false);

    const onSubmit = (e) => {
        e.preventDefault();
        const captchaValue = recaptchaRef.current.getValue();
        if (captchaValue) {
            setSubmiting(true);
            const formData = { name, email, subject, phone, message };
            const url = 'http://v2.badan.com.sa/api/forms/submit/contact_us?token=921b7c8fd1c4d44d0366f7373a7ca6'
            const body = { form: formData }
            fetch(url, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(body),
            })
                .then(entry => entry.json())
                .then(entry => {
                    setShow(true);
                    setSubmiting(false);
                })
        }
    }

    return (
        <div id="contact">
            <Toast style={toastStyle} onClose={() => setShow(false)} show={show} delay={4000} autohide>
                <Toast.Header>
                    <strong className={`${language === 'en' ? 'mr-auto' : 'ml-auto'}`}>{content.para11}</strong>
                </Toast.Header>
                <Toast.Body>{content.para12}</Toast.Body>
            </Toast>
            <AppCarousel
                title={content.para1}
                subTitle={content.para2}
                titleAnimationDelay="1s"
                subTitleAnimationDelay="10s"
                medias={medias} />
            <section className="page-section pt-5">
                <div className="container">
                    <div className="text-center mb-4">
                        <h1 className="text-primary font-weight-bold mb-4">{content.para3}</h1>
                        <p className="font-medium px-md-5 px-3">{content.para4}</p>
                    </div>
                </div>
                <div className="location-banner">
                    <div className="container h-100">
                        <div className="row align-items-center h-100">
                            <div className="col-lg-5 offset-lg-7">
                                <iframe
                                    title="Badan"
                                    src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3624.750751613389!2d46.70772521499977!3d24.701093484129384!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f03a0132cc509%3A0x88c56b83445f0cb0!2sBADAN%20HEAD%20OFFICE!5e0!3m2!1sen!2ssa!4v1580964949452!5m2!1s${language}!2ssa`}
                                    width="100%"
                                    height="350"
                                    frameBorder="0"
                                    allowFullScreen="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="contact-form py-5">
                <div className="container">
                    <h1 className="text-primary text-center font-weight-bold mb-4">{content.para5}</h1>
                    <div className="row mb-5">
                        <div className="col-md-4 text-center mb-md-0 mb-3">
                            <i className="d-block icon-phone mb-3 icon" />
                            <a href="tel:" className="text-dark font-medium">{content.para6}</a>
                        </div>
                        <div className="col-md-4 text-center mb-md-0 mb-3">
                            <i className="d-block icon-envelope mb-3 icon" />
                            <a className="text-dark font-medium" href="mailto:">{content.para7}</a>
                        </div>
                        <div className="col-md-4 text-center">
                            <i className="d-block icon-map-marker mb-3 icon" />
                            <p className="m-0 text-dark font-medium">{content.para8}</p>
                        </div>
                    </div>
                    <form onSubmit={onSubmit}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-4">
                                    <input type="text" name="name" className="form-control" onChange={(e) => setName(e.target.value)} placeholder={content.para13} required />
                                </div>
                                <div className="mb-4">
                                    <input type="email" name="email" className="form-control" onChange={(e) => setEmail(e.target.value)} placeholder={content.para14} required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" />
                                </div>
                                <div className="mb-4">
                                    <input type="text" name="subject" className="form-control" onChange={(e) => setSubject(e.target.value)} placeholder={content.para15} />
                                </div>
                                <div className="mb-md-0 mb-4">
                                    <input type="text" name="phone" className="form-control" onChange={(e) => setPhone(e.target.value)} placeholder={content.para16} required />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <textarea name="message" placeholder={content.para17} onChange={(e) => setMessage(e.target.value)} className="form-control h-100" required />
                            </div>
                            <div className="col-12 mt-4">
                                <div className="row">
                                    <div className="col-md-6">
                                        <ReCAPTCHA
                                            ref={recaptchaRef}
                                            sitekey="6LdA1tYUAAAAACauRQCjMMvlrJftOxVON-3BJpfC"
                                            hl={language}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <button className="btn btn-submit float-right ml-auto py-3 px-4 mt-4 mt-md-0" type="submit" disabled={submiting}>
                                            {submiting ? content.para9 : content.para10}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </section>

        </div>
    )
}

const mapStateToProps = (state) => ({
    language: state.home.language,
    english: state.contact.english,
    arabic: state.contact.arabic,
});

export default connect(mapStateToProps, {})(Contact);
