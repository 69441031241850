import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Header from "./common/header/Header";
import Home from "./home/Home";
import About from "./about/About";
import Projects from "./projects/Projects";
import Services from "./services/Services";
import Resources from "./resources/Resources";
import ChooseUs from "./choose-us/ChooseUs";
import Footer from "./common/footer/Footer";
import './App.scss';
import Contact from "./contact/Contact";
import {Provider} from 'react-redux';
import store from "./redux/store";

export default function App() {
  return (
    <Provider store={store}>
    <Router>
        <Header />
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/projects">
            <Projects />
          </Route>
          <Route path="/services">
            <Services />
           </Route>
          <Route path="/resources">
            <Resources />
          </Route>
          <Route path="/choose-us">
            <ChooseUs />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
        </Switch>
        <Footer />
    </Router>
    </Provider>
  );
};