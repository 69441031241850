import React from 'react'
import AppCarousel from '../common/app-carousel/AppCarousel';
import { Container } from 'react-bootstrap';
import './Services.scss';
import { connect } from 'react-redux';

const medias = [
    {
        url: '/videos/services.mov',
        title: 'WE ARE SYSTEMATIC LANDSCAPE SPECIALISTS',
        mediaType: 'video',
        titleAnimationDelay: '1s'
    }
];

function Services({english, arabic, language}) {
    const content = language === 'en' ? english :  arabic;
    const { gallery } = content
    return (
        <div id="services">
            <AppCarousel
                title={content.para1}
                titleAnimationDelay="1s"
                medias={medias} />
            <section className="page-section projects mb-5">
                <Container>
                    <div className="px-md-5 mx-md-2 mb-5">
                        <h1 className="text-primary text-center font-weight-bold mt-5 mb-4">{content.para2}</h1>
                        <p className="text-center mb-2 text-secondary font-medium">{content.para3}</p>
                    </div>
                    <h4 className="text-primary text-center font-weight-bold mb-4">{content.para4}</h4>
                    <div className="item-gallery">
                        {
                            gallery.map(item => {
                                return (
                                    <div key={item.id} className="item-wrapper">
                                        <img className="item" src={item.imgUrl} alt="gallery-img" />
                                        <div className="item-text-wrapper">
                                            <h4>{item.title}</h4>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </Container>
            </section>
        </div>
    )
}

const mapStateToProps = (state) => ({
    english: state.services.english,
    arabic: state.services.arabic,
    language: state.home.language,
  });

export default connect(mapStateToProps)(Services)
