import React, { useState } from 'react';
import { Carousel } from 'react-bootstrap';
import './AppCarousel.scss';
import PropTypes from 'prop-types'
import { connect } from "react-redux";


function AppCarousel(props) {
    const {
        medias,
        title,
        subTitle,
        titleAnimationDelay,
        subTitleAnimationDelay,
        isCarousel,
        language
    } = props
    return (
        <section className="page-section carousel-wrapper position-relative">
            {isCarousel
                ? <BCarousel medias={medias} />
                : <SingleMedia media={medias[0]} />
            }
            <div className="centered-caption">
                <h1
                    className="font-weight-bold text-uppercase title"
                    style={{ animationDelay: titleAnimationDelay,direction:language==='ar' ? 'rtl' : 'ltr' }}>{title}</h1>
                <p className="sub-title"
                    style={{ animationDelay: subTitleAnimationDelay }}>{subTitle}</p>
            </div>
        </section>
    );
}

const SingleMedia = function ({ media }) {
    return (
        <div className="home-carousel">
            {
                media.mediaType === 'image' ?
                    (<img
                        className="d-block carousel-media w-100 h-100"
                        src={media.url}
                        alt="Slide Img"
                    />
                    ) :
                    (
                        <video className="d-block carousel-media w-100 h-100" autoPlay loop muted>
                            <source src={media.url}></source>
                        </video>
                    )
            }
        </div>
    )
}
const BCarousel = function ({ medias }) {
    const [duration, setDuration] = useState(1000)
    const onCarouselChanges = (eventKey, direction, event) => {
        console.log({ eventKey, direction, event })
        const media = medias[eventKey];
        if (media.mediaType === 'video') {
            const videoEl = document.getElementById('carousel-video-' + media.id);
            videoEl.play();
            setDuration(media.duration);
            setTimeout(() => {
                videoEl.pause();
                videoEl.currentTime = 0;
            }, media.duration + 1000)
        }
        else {
            setDuration(media.duration ? media.duration:  1000);
        }
    }
    return (
        <Carousel
            interval={duration}
            controls={false}
            indicators={false}
            fade={true}
            touch={false}
            pauseOnHover={false}
            onSelect={onCarouselChanges}
            className="home-carousel w-100">
            {
                medias.map(media =>
                    <Carousel.Item key={media.id} className="h-100">
                        {
                            media.mediaType === 'image' ?
                                (<img
                                    className="d-block carousel-media w-100 h-100"
                                    src={media.url}
                                    alt="Slide Img"
                                />
                                ) :
                                (
                                    <video className="d-block carousel-media w-100 h-100" id={'carousel-video-' + media.id} loop muted>
                                        <source src={media.url}></source>
                                    </video>
                                )
                        }
                    </Carousel.Item>
                )
            }
        </Carousel>
    )
}

AppCarousel.propTypes = {
    medias: PropTypes.arrayOf(PropTypes.shape({
        url: PropTypes.string.isRequired,
        type: PropTypes.string,
        duration: PropTypes.number,
    })).isRequired,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    titleAnimationDelay: PropTypes.string,
    subTitleAnimationDelay: PropTypes.string,
    isCarousel: PropTypes.bool
};
AppCarousel.defaultProps = {
    isCarousel: false
};


const mapStateToProps = (state) => ({
    language: state.home.language,
  });
  
export default connect(mapStateToProps, {})(AppCarousel);