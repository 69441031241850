import React from 'react';
import './Gallery.scss';

function Gallery({ medias }) {
    return (
        <div className="wrapper" id="app-gallery">
            {medias.map(media => {
                return (
                    <div key={media.id} className="item-wrapper">
                        <img className="item" src={media.url} alt="gallery-img"/>
                        <div className="item-text-wrapper px-3">
                            <h4 className="text-center">{media.title}</h4>
                        </div>
                    </div>
                )
            })}
        </div>
    );
}
export default Gallery;
