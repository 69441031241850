import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers';

const logger = store => {
  return next => {
    return action => {
      console.log('ACTION DISPACH', action);
      console.log('STATE', store.getState());
      return next(action);
    };
  };
};

const store = createStore(reducers, applyMiddleware(logger, thunk));

export default store;
