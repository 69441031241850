import {combineReducers} from 'redux';
import homeReducer from './homeReducer';
import aboutUsReducer from './aboutUsReducer'
import servicesReducer from './servicesReducer'
import resourceReducer from './resourcesReducer'
import projectReducer from './projectReducer'
import contactReducer from './contactReducer'
import chooseUsReducer from './chooseUsReducer'


export default combineReducers({
  home: homeReducer,
  aboutUs: aboutUsReducer,
  services: servicesReducer,
  resources: resourceReducer,
  projects: projectReducer,
  contact: contactReducer,
  chooseUs: chooseUsReducer
});
